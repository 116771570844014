<!-- 工作组详情 -->
<template>
  <div class="fun_contact_workteam_detail">
    <div class="wrapper">
      <div class="title_group">
        <div class="title" v-if="0">{{chatName || '&nbsp;'}}</div>
        <input
          type="text"
          class="work_name"
          v-model="work_name"
          @blur="changeWorkName"
          @keyup.enter="$refs.work_name.blur()"
          maxlength="20"
          ref="work_name"
        >
      </div>
      <div class="list_group">
        <div class="list_unit" v-for="(item, index) in chat_user_list" :key="index" >
          <WokrteamDetailUnit :userData="item" width="90px" fontsize="16px" fontpaddingtop="22px"></WokrteamDetailUnit>
        </div>
      </div>
      <div class="btn_group">
        <div class="btn cp un_sel" @click="gotoDialog">进入对话</div>
      </div>

    </div>
  </div>
</template>
<script>
import WokrteamDetailUnit from '@/components/workteam/detailUnit.vue';
export default {
  components:{
    WokrteamDetailUnit,
  },
  data(){
    return {
      userData: null,
      // chatId: this.$route.params.id,
      chatId: this.$api.moduleTask.getRouteParamsId(),
      chatData: null, //工作组用户列表
      chatName: '', //用于修改群名
      work_name: '',
    }
  },
  computed:{
    workteam_detail_name(){
      return this.$store.state.contact.workteam_detail_name;
    },
    /**消息盒子列表 */
    message_box_list(){
      let message_box_list = this.$store.state.message_box_list;
      if(message_box_list == null){
      }
      return message_box_list;
    },
    /**联系人信息 */
    contact_data(){
      let contact_data = this.$store.state.contact_data;
      return contact_data;
    },
    /**
     * 工作组列表
     */
    workteam_list(){
      let contact_data = this.contact_data;
      let workteam_list = this.$store.state.workteam_list;
      if(contact_data == null && workteam_list != null){
        // let chatId = this.$route.params.id;
        let chatId = this.$api.moduleTask.getRouteParamsId();
        contact_data = workteam_list.find((item)=>{
          return item.chatId == chatId;
        })
        this.$store.commit('set_contact_data', contact_data);
      }
      return workteam_list;
    },
    // 聊天用户列表
    chat_user_list(){
      return this.$store.state.chat_user_list;
    },
  },

  watch:{
    workteam_detail_name: function(){},
    '$route.params.id': async function(newVal){
      this.chatId = this.$api.moduleTask.getRouteParamsId(),
      await this.getTeamDetailData();
    },
    message_box_list: {
      handler: function(newval){
        if(newval == null){
          this.$store.dispatch('get_message_box_list');
        }
      },
      deep: true,
    },
    contact_data:{
      handler: function(val){
        if(val && val.chatName){
          this.chatName = val.chatName;
          this.work_name = this.contact_data.chatName;
        }
      },
      deep: true,
    },
    workteam_list:{
      handler: function(){},
      deep: true,
    },
    chat_user_list:{
      handler: function(){},
      deep: true,
    },
  },
  methods:{
    /**
     * 获取工作组信息
     */
    async getTeamDetailData(){
      let chatId = this.chatId;
      let res = await this.$http.request('/projectFriends/chatUserList',{chatId});
      if(res.contents.list.length != 0){
        this.$set(this, 'chatData', res.contents.list);
      }
      this.$store.commit('set_chat_user_list', res.contents.list);

    },
    // 获取用户信息
    async getUserById(){
      // 模拟数据
      let examData = `{"userPassword":"4475ef26554a7322d817105864a20179","userStatus":1,"punishNum":0,"listSkill":[],"loginName":"18611167834","userPosition":"","email":"yuxiang@unisonchina.com","personalDescribe":"","maxUserNums":0,"thumbnail":"2016/01/08/1452234625680.png","userVip":0,"updateTime":1449112153000,"userName":"UUUU","userId":5,"userToken":"d2fcc9646609b3d33d9d7bae06563bfe","createTime":1449112153000,"phone":18611167834,"userType":1,"hxPwd":"123456","threeOne":0}`;
      examData = JSON.parse(examData);
      // console.log(examData);
      this.$set(this, 'userData', examData);
    },
    /**
     * 跳转到会话页
     */
    async gotoDialog(){
      // let chatId = this.$route.params.id;
      let chatId = this.$api.moduleTask.getRouteParamsId();
      // 查询当前消息盒子列表是否有当前用户的会话 如果没有创建
      let message_box_list = this.$tools.deepClone(this.message_box_list);
      if(!message_box_list){
        await this.$store.dispatch('get_message_box_list');
        message_box_list = this.$tools.deepClone(this.$store.state.message_box_list);
      }
      let hasDialog = message_box_list.find((item)=>{
        return item.chatId == chatId;
      })
      // 不存在
      if(!hasDialog){
        let message_box_list_unit = {
          // masterUserid: this.$tools.getUserId(),
          chatType: 1,
          chatId: chatId,
          messageContent: '',
          name: this.contact_data.chatName,
          photo: this.contact_data.photo,
        }
        this.$store.commit('set_message_box_unit',message_box_list_unit );
        let not_top_index = message_box_list.findIndex( item => {
          return !item.istop;
        })
        if(not_top_index != -1){
          message_box_list.splice(not_top_index, 0, message_box_list_unit);
        }else{
          message_box_list.unshift(message_box_list_unit);
        }
        this.$store.commit('set_message_box_list', message_box_list);
      }

      this.$router.push({name:'fun_task_dialog_group', params:{id: this.$tools.btoa(this.contact_data.chatId)}})
    },
    async changeWorkName(){
      let {chatId, work_name} = this;
      if(work_name == ''){
        // this.$toast({icon:'none',title:'提示',desc:'群聊名不能为空',duration:1000});
        this.$notice({desc: '群聊名不能为空', duration: '1000', mask: false})
      }else{
        let res = await this.$http.request('/project/chatNameEdit', {chatId, chatName: work_name});
        if(res.message == 'success'){
          this.$notice({desc: '修改成功', type:'success', duration: '1000', mask: false})
          this.$api.changeGroupName({chatId, chatName: work_name})
        }

      }
    }
  },
  async mounted() {
    this.getTeamDetailData();
    if(this.contact_data && this.contact_data.chatName){
      this.chatName = this.contact_data.chatName;
      this.work_name = this.contact_data.chatName;
    }
  },
}
</script>

<style lang="scss" scoped>
.fun_contact_workteam_detail{
  width:100%;
  height: 100%;
  background-color:$main_bg_color;
  .wrapper{
    @include hidescrollbar;
    @include flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    z-index: 2;
    width:100%;
    height: 100%;
    .title_group{
      @include bbox;
      flex-shrink: 0;
      line-height: 64px;
      padding-left: 20px;
      width:100%;
      // position: sticky;
      // top:0;
      background-color:#fff;
      z-index: 3;
      font-size:18px;

      font-weight:400;
      color:rgba(51,51,51,1);
      .work_name{
        @include bbox;
        @include transition;
        padding: 0 10px;
        font-size: 18px;
        line-height: 36px;
        border-radius: 4px;
        border: 1px solid transparent;
        width: 308px;
        outline: none;
        &:hover{
          border: 1px solid #ccc;
        }
      }
    }
    .list_group{
      @include bbox;
      @include flex;
      @include scrollbar;
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: flex-start;
      height:100%;
      flex:1;
      width:100%;
      padding: 20px 0;
      overflow-y: auto;
      .list_unit{
        margin: 20px;
      }
    }
    .btn_group{
      @include flex;
      justify-content: center;
      flex-shrink: 0;
      width:100%;
      padding: 30px 0;
      .btn{
        @include transition;
        padding: 0 65px;
        line-height:50px;
        background-color: $main_blue;
        border-radius:4px;
        font-size:18px;

        font-weight:400;
        color:rgba(255,255,255,1);
        &:hover{
          background-color: $main_blue_hover;
        }
      }
    }
  }
}
</style>
