var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fun_contact_workteam_detail" }, [
    _c("div", { staticClass: "wrapper" }, [
      _c("div", { staticClass: "title_group" }, [
        0
          ? _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.chatName || " ")),
            ])
          : _vm._e(),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.work_name,
              expression: "work_name",
            },
          ],
          ref: "work_name",
          staticClass: "work_name",
          attrs: { type: "text", maxlength: "20" },
          domProps: { value: _vm.work_name },
          on: {
            blur: _vm.changeWorkName,
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.$refs.work_name.blur()
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.work_name = $event.target.value
            },
          },
        }),
      ]),
      _c(
        "div",
        { staticClass: "list_group" },
        _vm._l(_vm.chat_user_list, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "list_unit" },
            [
              _c("WokrteamDetailUnit", {
                attrs: {
                  userData: item,
                  width: "90px",
                  fontsize: "16px",
                  fontpaddingtop: "22px",
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "btn_group" }, [
        _c(
          "div",
          { staticClass: "btn cp un_sel", on: { click: _vm.gotoDialog } },
          [_vm._v("进入对话")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }